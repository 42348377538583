/** @format */

@import './order/order.css';
@import './style_fix_size.css';
@import './products/product.css';
@import './icon.css';

body {
    /* color: #313942; */
    color: var(--main-default);
}

.ladiui.wrapper {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    min-width: 1366px;
    height: 0;
}

.ladiui.ladiui.my-account-avata img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 90;
}

.ladiui.main-custom {
    position: relative;
    margin-left: 70px;
    width: calc(100% - 70px);
    height: 100vh;
    overflow: auto;
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 20px 0px 20px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 0;
}

/* .ladiui.btn-group {
  padding: 14px 0px 14px 0px;
} */

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    /* left: 30px; */
    position: absolute;
    width: 400px;
    right: 122px;
    margin-top: 15px;
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;
}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, 0.05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;
}

.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

.ladiui.tool-help {
    padding: 20px 12px 20px 12px;
}

.ladiui.tool-help .dropdown-menu {
    left: 0;
    position: absolute;
    margin-top: 15px !important;
    /* right: 0; */
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    cursor: pointer;
    pointer-events: none;
    color: white;
    font-size: 0;
}

.ladiui.tool-language img {
    border-radius: 20px;
}

.ladiui.tool-language .dropdown-menu {
    right: 0px;
    min-width: 150px;
    position: absolute;
    margin-top: 15px;
    /* right: 0; */
}

.ladiui.my-account {
    cursor: pointer;
    width: 74px !important;
    min-width: auto;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    padding: 12px 0px 12px 12px;
    pointer-events: none;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    /* left: 220px; */
    position: absolute;
    right: 24px;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account {
    position: relative;
}

.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
}

.ladiui.account-header-text {
    /* pointer-events: none; */
    /* display: grid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ladiui.account-content {
    padding: 14px 24px 0;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: 0.5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
    /* position: relative; */
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

/* .ladiui.account-duration {
    margin-left: 8px;
} */

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.aside .ladiui.nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 70px;
    height: 100vh;
    background-color: var(--main-line);
    transition: width 0.3s;
}

.ladiui.logo-item.logo {
    padding: 16px 20px;
    position: relative;
    opacity: 1;
}

.ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.aside .ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity 0.5s;
}

.aside .ladiui.nav:hover .logo-item.logo {
    position: absolute;
}

.aside .ladiui.nav:hover {
    display: block;
    width: 215px;
}

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity 0.2s, width 0.3s;
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.aside .ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 150px;
}

.aside .ladiui.nav:hover .menu-item {
    width: 215px;
}

.ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 70px;
    transition: width 0.3s;
    border-left: 2px solid var(--secondary-color);
}

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}

.ladiui.logo-item.logo-hover {
    padding: 16px 59px 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    border-left: 2px solid var(--main-primary);
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

.aside .ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width 0.3s;
}

.aside .ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
}

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity 0.2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child-item.active a {
    color: var(--main-primary);
}

.ladiui.menu-child-item:last-child {
    padding-bottom: 0px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}

.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 330px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em;
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

.ladiui-aside {
    width: 200px;
    height: 100%;
    position: relative;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

/*-------------MINI NAVBAR----------------*/

body.mini-navbar .ladiui-aside {
    width: 90px !important;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

body.mini-navbar .ladiui-main {
    width: calc(100% - 90px) !important;
}

body.mini-navbar .ladiui.nav > .menu > li > a > i {
    font-size: 18px;
    margin: 0px auto;
    position: initial;
    border-radius: 50%;
    line-height: 29px;
    width: 40px;
    height: 40px;
}

body.mini-navbar .ladiui.nav > .menu > li > a {
    text-align: center;
    padding: 10px 6px;
    line-height: 20px;
    font-size: 14px;
    display: grid;
    width: 100%;
}

body.mini-navbar .ladiui.setting-bottom a {
    padding: 14px !important;
    display: grid !important;
    text-align: center;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu {
    position: absolute;
    left: 98px;
    top: 0px;
    border-radius: 4px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
    display: none;
    background: #fff;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu.show {
    display: block;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li {
    list-style: none;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li > a {
    white-space: nowrap;
    padding: 10px 20px;
}

body.mini-navbar .ladiui.nav > .menu > li.open > .ladiui.submenu > li > a::before {
    left: 7px !important;
    display: none;
}

body.mini-navbar .ladiui-nav-toggle {
    width: 91px !important;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

/*-----------END MINI NAVBAR---------------------*/

.ladiui-logo {
    text-align: left;
    height: 50px;
    line-height: 46px;
    width: 200px;
    float: left;
}

.ladiui-logo img {
    width: 60%;
    vertical-align: middle;
    display: inline-block;
}

.ladiui-main {
    width: 100%;
    float: right;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-topbar {
    height: 50px;
    background-color: #1853fa;
    border-bottom: 1px solid #eaedf0;
    background: linear-gradient(90deg, rgba(12, 97, 242, 1), rgba(0, 65, 179, 1));
    display: flex;
}

.mini-navbar .ladiui-nav-toggle i {
    margin-left: 0px;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.mini-navbar .ladiui-logo {
    margin-left: 18px;
    transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
}

.ladiui-nav-toggle {
    width: 48px;
    float: left;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.ladiui-nav-toggle i {
    font-size: 18px;
    margin-top: 8px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    padding: 7px;
}

.ladiui-topbar .topbar-left {
    width: 40%;
    float: left;
}

.ladiui-topbar .topbar-right {
    width: 60%;
    float: right;
    text-align: right;
    margin-right: 15px;
}

.ladiui.topbar-searchbox {
    position: relative;
    display: inline-block;
    height: 50px;
    line-height: 49px;
    margin-left: 30px;
}

.ladiui.topbar-searchbox > i {
    position: absolute;
    top: 17px;
    left: 12px;
    color: #8697a8;
}

.ladiui.topbar-search {
    display: inline-block;
    width: 290px;
    height: 36px;
    padding: 0px;
    text-indent: 40px;
    color: #8697a8;
    font-size: 14px;
    background-color: #f8fafb;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ladiui.topbar-search::before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 60px;
    background: red;
}

.topbar-profile {
    font-size: 15px;
    height: 50px;
    line-height: 45px;
    float: right;
    margin-left: 15px;
}

.topbar-profile a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
}

.topbar-profile li.ladiui-dropdown ul > li > a {
    transition: background-color 0.15s ease-out;
}

.topbar-profile li.ladiui-dropdown ul > li > a:hover {
    background-color: #f6f7f9 !important;
}

.topbar-profile .ladiui.avatar {
    background-color: #1853fa;
    color: white;
    width: 35px;
    height: 35px;
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: -7px;
    margin-right: 0px;
    background-size: contain !important;
}

.topbar-profile .ladiui-navbar > li > ul {
    position: absolute;
    right: 0px;
}

.ladiui-topbar .ladiui-navbar > li > ul {
    min-width: 290px;
}

.ladiui-topbar .ladiui-navbar > li > ul > li > a {
    padding: 2px 20px;
}

.topbar-profile .ladiui-navbar > li > ul::before {
    content: '';
}

.topbar-profile .ladiui-navbar > li > ul > li:last-child > a.notification-item {
    border-bottom: 0px;
}

.notifications {
    z-index: 5;
}

.notifications > li > ul {
    padding: 0px !important;
}

.notifications .notification-item:hover {
    background: #ebedf2 !important;
}

.notifications .notification-item {
    display: -webkit-inline-box;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #f7f8fa;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    height: auto;
    line-height: 28px;
}

.notifications .has-message {
    position: relative;
}

.notifications .has-message::after {
    position: absolute;
    width: 5px;
    height: 5px;
    background: red;
    content: '';
    border-radius: 50%;
    top: 1px;
    right: 0px;
}

.notifications .notification-item .notification-time {
    font-size: 12px;
    float: left;
    height: 28px;
    line-height: 28px;
    width: 100%;
    text-align: left;
}

.notifications .notification-title {
    font-size: 14px;
}

.notifications .notification-item .notification-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 2.5rem;
    flex: 0 0 2.5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.notifications .notification-item .notification-details {
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
}

.notifications .notification-item .notification-icon i {
    font-size: 30px;
    color: #5d78ff;
}

.notifications .notification-item .notification-details .notification-item-title {
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    font-size: 1rem;
    font-weight: 400;
    color: #6c7293;
}

.notifications .notification-item .notification-details .notification-item-time {
    font-size: 1rem;
    font-weight: 300;
    color: #a7abc3;
}

.topbar-profile .ladiui-navbar.-horizontal > li {
    line-height: 55px;
    text-align: left;
}

.topbar-profile .ladiui-navbar.-horizontal > li:first-child {
    padding-left: 12px;
}

.app-tools li a {
    font-size: 15px;
}

.app-tools li i,
.topbar-profile .ladiui-navbar.-horizontal > li > ul > li > a > i {
    font-size: 14px;
    margin-right: 6px;
    vertical-align: middle;
}

.topbar-profile .ladiui-navbar.-horizontal > li > a {
    height: 50px;
    line-height: 53px;
    color: #fff;
    font-size: 15px;
}

.topbar-profile .ladiui-navbar li:hover > a:first-child,
.topbar-profile .ladiui-navbar li.-active > a:first-child {
    background: transparent;
}

.ladiui-store-list li a i {
    font-size: 30px;
}

.topbar-tools .ladiui.list {
    line-height: 50px;
    height: 50px;
}

.topbar-tools .ladiui.list > li > a {
    text-decoration: none;
    color: #44566c;
    vertical-align: middle;
    display: block;
    padding: 0px 10px;
}

.topbar-right > div {
    display: -webkit-inline-box;
}

.app-packages span {
    display: inline-block;
    margin: 0px 20px;
    color: #fff;
}

.app-packages {
    padding-right: 24px;
    border-right: 1px solid #335de7;
    line-height: 49px;
}

.app-packages .upgrade {
    display: inline-block;
    margin-top: 7px;
}

.ladiui-content {
    padding: 10px 30px 30px 20px;
}

.ladiui-table-actions {
    /* margin-bottom: 24px; */
    margin-bottom: 12px;
    position: relative;
    z-index: 1;
    min-height: 40px;
    display: flex;
    z-index: 50;
    justify-content: space-between;
}

.ladiui-table-actions.reports {
    margin-bottom: 12px !important;
}

.ladiui.search-actions {
    float: left;
    line-height: 39px;
}

.ladiui.actions {
    float: right;
}

.ladiui.actions > button,
.ladiui.actions > div {
    margin: 0px 6px;
}

.ladiui.actions > button,
.ladiui.actions > div:last-child {
    margin-right: 0px;
}

.ladiui.search-keyword {
    width: 290px;
    position: relative;
}

.ladiui.search-keyword .ladiui.input {
    text-indent: 5px;
    padding-right: 35px;
}

.ladiui.search-keyword i {
    position: absolute;
    top: 0px;
    color: #8697a8;
    right: 0px;
    cursor: pointer;
    padding: 9px;
}

/* .ladiui.nav>.menu>li {
  list-style: none;
  position: relative;
} */
/* 
.ladiui.menu {
  margin: 0px;
  padding: 0px;
} */
/* 
.ladiui.nav>.menu>li>a {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  color: #44566c;
  font-style: normal;
  font-stretch: normal;
  line-height: 31px;
  letter-spacing: normal;
  border-radius: 0px 30px 30px 0px;
  transition: color 0.6s ease-out;
}

.ladiui.nav>.menu>li>a>i {
  margin-right: 7px;
  font-size: 17px;
  border-radius: 50%;
  padding: 6px;
}

.ladiui.nav>.menu>li>a:hover {
  color: #1853fa;
}

.ladiui.nav>.menu>li>a:hover,
.ladiui.nav>.menu>li>a:focus,
.ladiui.nav>.menu>li>a:active {
  background-color: #f8fafb;
} */

.ladiui.submenu {
    background-color: #f8fafb;
    display: none;
    padding: 0px;
}

/* 
.ladiui.nav>.menu>li.open>a {
  background: #e8f0fe;
  color: #1853fa;
}

.ladiui.nav>.menu>li.open>a>i {
  border-color: #1853fa;
}

.ladiui.nav>.menu>li.open>.ladiui.submenu {
  display: block;
  padding: 0px;
  transition: width 0.1s, height 0.1s, transform 0.1s, opacity 0.1s;
} */

.ladiui.submenu > li {
    list-style: none;
}

.ladiui.submenu > li > a {
    color: #44566c;
    padding: 10px 0px 10px 45px;
    display: block;
    position: relative;
}

.ladiui.submenu > li > a:hover {
    color: #1853fa;
}

/* 
.ladiui.nav>.menu>li>a>.ladiui.pill {
  background: #ff3d57;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px 5px;
  position: absolute;
  right: 18px;
} */

.ladiui.pagination-block {
    width: 100%;
    margin-top: 20px;
}

.ladiui-search-box {
    background: #fff;
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    z-index: 0;
    margin-bottom: 24px;
    display: flex;
}

.ladiui.showing {
    width: 300px;
    color: #8697a8;
    font-size: 14px;
    float: left;
}

.ladiui.showing-total {
    width: 300px;
    color: #8697a8;
    font-size: 14px;
    float: right;
    line-height: 40px;
    text-align: right;
}

/* 
.ladiui.pagination>ul>li {
  display: inline-block;
  margin: 2px;
}

.ladiui.pagination>ul>li>a {
  text-decoration: none;
  width: 30px;
  line-height: 27px;
  display: block;
  padding-top: 2px;
  text-align: center;
  color: #44566c;
  border-radius: 3px;
}

.ladiui.pagination>ul>li.disabled>a {
  cursor: not-allowed;
  color: #c7c6c6 !important;
  background-color: #fff !important;
}

.ladiui.pagination>ul>li:first-child>a {
  border-radius: 5px 0px 0px 5px;
}

.ladiui.pagination>ul>li:last-child>a {
  border-radius: 0px 5px 5px 0px;
} */

*/
/* .ladiui.pagination>ul>li.active>a,
.ladiui.pagination>ul>li>a.active,
.ladiui.pagination>ul>li>a:active,
.ladiui.pagination>ul>li>a:hover {
  color: #fff;
  background-color: #1853fa;
} */

/* .ladiui.pagination>ul>li>a>i {
  font-size: 12px;
} */

.ladiui.setting-bottom {
    position: absolute;
    bottom: 48px;
    width: 100%;
    background: #fafafa;
}

.ladiui.setting-bottom a {
    padding: 15px 23px;
    color: #44566c;
    display: block;
    font-size: 12px;
}

.ladiui.data-limit {
    float: left;
}

.ladiui.data-limit > span {
    display: block;
    float: left;
    margin-right: 10px;
}

.ladiui.per-page {
    width: 85px;
    float: right;
}

.ladiui.form-filter > div:first-child {
    margin-left: 0px;
}

.ladiui.form-filter > div {
    display: inline-block;
    margin: 0px 10px;
}

.ladiui.select.per-page {
    min-width: 75px;
}

.ladiui-modal-body .ladiui-tabs {
    border-bottom: 1px solid rgba(6, 21, 40, 0.05);
}

.ladiui-tabs li.active a {
    padding-bottom: 7px;
}

.group-prices .price-item {
    width: 100%;
}

.product-image-preview {
    width: 100%;
    height: 306px;
    background: #fafafa;
    border: 1px solid #ebedf2;
}

.slider-products .item {
    margin-top: 15px;
    width: 70px;
    height: 70px;
    background: #fafafa;
    text-align: center;
    line-height: 70px;
    float: left;
}

.slider-products .owl-nav {
    position: absolute;
    width: 100%;
    top: 20%;
}

.slider-products .owl-nav .owl-prev {
    position: absolute;
    left: 0px;
}

.slider-products .owl-nav .owl-next {
    position: absolute;
    right: 0px;
}

.slider-products .owl-nav .owl-prev span,
.slider-products .owl-nav .owl-next span {
    font-size: 30px;
}

.slider-products .owl-nav [class*='owl-']:hover {
    background: transparent;
}

.slider-products .owl-nav [class*='owl-']:hover span {
    color: #1853fa;
}

.tox .tox-statusbar {
    display: none !important;
}

.margin-top-15 {
    display: inline-block;
    margin-top: 15px;
}

.btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.fixed {
    position: fixed;
}

.ladiui-product-left {
    width: 400px;
    float: left;
}

.ladiui-product-right {
    width: calc(100% - 424px);
    float: right;
    margin-left: 24px;
}

.ladiui-product-course-left {
    width: 40%;
    float: left;
}

.ladiui-product-course-right {
    width: 60%;
    float: right;
    padding-left: 24px;
}

.ladiui-modal-footer > .ladiui.button {
    margin: 0px 5px;
}

.variant-list {
    overflow-y: scroll;
    border-top: 1px solid #dfe3e8;
    max-height: 263px;
}

.aspect-ratio {
    position: relative;
    float: left;
    border: 1px solid rgba(195, 207, 216, 0.3);
    text-align: center;
    background: #fafafa;
    width: 400px !important;
    height: 316px !important;
    float: inherit !important;
    margin: 0px auto;
}

.variant-list .aspect-ratio {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.aspect-ratio::before {
    z-index: 1;
    content: '\ea38';
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    font-family: 'LadiIcons';
    left: 0;
    font-size: 26px;
    color: #dedede;
}

.ladiui-widget-body ul li a:hover,
.ladiui-widget-body ul li a.active {
    color: #1853fa;
}

.ladiui-col-3 {
    width: 30%;
}

.ladiui-col-7 {
    width: 65%;
}

#tab-variants .ladiui-form-group > .ladiui-col {
    width: 31%;
    float: left;
    margin-right: 19px;
    margin-bottom: 20px;
}

.variant-options {
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: none;
}

.variant-options .ladiui-widget-body {
    padding: 15px 0px;
    overflow: hidden;
    border-bottom: 1px solid #f2f3f4;
}

.ladiui.group-prices {
    clear: both;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
}

.form-tags .select2-selection__rendered {
    width: 497px !important;
}

.variant-option-list {
    margin-top: 10px;
}

/* .variant-option-item {
    height: 55px;
} */

.variant-option-item > div {
    display: inline-block;
    float: left;
}

.variant-option-item > div:last-child {
    float: right;
    height: 32px;
    line-height: 32px;
}

.ladiui-form-group.variant-option-head {
    margin-top: 24px;
    margin-bottom: 16px;
}

.variant-option-item .variant-option-name {
    width: 250px;
    position: relative;
}

.variant-option-item .variant-option-name .btn-save-option-name {
    position: absolute;
    right: 10px;
    color: #1c00c2;
    cursor: pointer;
    top: 10px;
}

.new-variant-option {
    color: inherit;
    display: block;
    margin-top: 15px;
}

.variant-option-item .variant-option-value .select2-search__field {
    width: 310px !important;
}

.variant-option-item .remove-variant-option {
    width: 20px;
}

.variant-option-item .remove-variant-option a {
    color: inherit;
}

.variant-option-head > span:first-child {
    width: 120px;
}

.variant-option-head > span:last-child {
    width: 350px;
    margin-left: 17px;
}

.variant-option-head > span {
    font-weight: 500;
    display: inline-block;
}

.order-stack-total {
    border-bottom: 0px !important;
}

.order-stack-total td {
    padding: 10px 4px;
}

.order-stack-total td strong {
    font-weight: 700;
    font-size: 18px;
}

.order-stack-total td:last-child {
    padding-right: 91px;
    width: 240px;
    text-align: right;
}

.order-customer {
    overflow: hidden;
    background: #fff;
    /* padding: 15px; */
    /* border-radius: 5px; */
    /* margin-bottom: 20px; */
    /* box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1); */
    overflow: inherit;
    /* min-width: 300px; */
    border-bottom: 1px solid #f1f3f4;
    /* padding: 24px 0px; */
}

.order-customer:last-child {
    border-bottom: 0px !important;
}

.customer-info {
    position: relative;
    padding-bottom: 20px;
}

.customer-info > span {
    display: block;
    line-height: 40px;
}

.customer-info > span:first-child {
    margin-left: 0px;
}

.customer-info > span > i {
    border: 1px solid #eaedf0;
    border-radius: 50%;
    padding: 6px;
    position: relative;
    top: 1px;
    font-size: 12px;
}

.customer-info strong {
    font-weight: 500;
}

.created-at {
    display: block;
    position: absolute;
    top: -38px;
    right: 0px;
    color: inherit;
}

.payment-box:first-child {
    margin-left: 0px;
}

.payment-box {
    width: 30%;
    float: left;
    margin: 0px 15px;
}

.payment-box ul {
    padding: 0px;
    margin-top: 10px;
}

.payment-box ul li {
    line-height: 30px;
    list-style: none;
}

.payment-box .ladiui.select {
    background: #f8fafb;
}

.order-heading h3 {
    font-size: 15px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}

.payment-box .order-heading {
    margin-top: 15px;
}

.ladiui-order-left {
    width: 70%;
    float: left;
    /* position: relative; */
}

.order-head-right .placed-on {
    margin-right: 30px;
}

.order-head-right .placed-on span {
}

.order-head-right .placed-on span:first-child {
    font-weight: 500;
}

.order-head-right .placed-on span:last-child {
}

/* .ladiui-order-right {
  width: 28%;
  float: right;
} */

.ladi-card.order-products .ladiui.table {
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
}

@media (max-width: 1000px) {
    .ladiui-topbar .topbar-right {
        width: 900px;
    }

    .ladiui.actions {
        width: 100vh;
    }
}

/*****************24042019***************/

.ladiui-table-actions .action-item {
    display: block;
    float: left;
    margin-right: 20px;
}

/*--------------------- LOGIN -------------------*/

.ladiui.auth .auth-left {
    float: left;
    width: 50%;
}

.ladiui.auth .auth-right {
    width: 50%;
    float: right;
}

.ladiui.auth .auth-left iframe {
    width: 100%;
    border: 0px;
    height: 100vh;
}

.ladiui.auth {
    background: url(https://builder.ladipage.vn/images/geometry2.png) repeat;
    color: #44566c;
}

.ladiui.auth h1.form-title {
    margin: 0px 0px 20px 0px;
    padding: 0px;
    font-size: 21px;
    text-align: center;
    font-weight: 500;
    color: #44566c;
}

.ladiui.auth .ladiui-login {
    padding: 30px;
    float: none;
    width: 424px;
    background: #fff;
    margin: 30px auto;
    border-radius: 5px;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    display: block;
}

.ladiui.auth .ladiui-logo {
    width: 290px;
    padding: 40px;
    margin: 0px auto;
    border: 0px;
    float: none;
}

.ladiui.auth .ladiui-logo img {
    width: 100%;
}

.ladiui.auth .ladiui-login-form .ladiui-form-group {
    margin-bottom: 15px;
    position: relative;
}

.ladiui.auth .ladiui-login-form label {
    margin: 8px 0px;
    display: block;
}

.ladiui.auth .ladiui-login-form .button {
    width: 100%;
    padding: 15px;
    font-size: 15px;
}

.ladiui.auth .social-login {
    text-align: center;
}

.ladiui.auth .social-login a.login-facebook,
.ladiui.auth .social-login a.login-google {
    display: inline-block;
    width: 150px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 0px;
    padding: 14px 10px;
    margin: 2px;
    line-height: 11px;
}

.ladiui.auth .social-login a.login-facebook {
    background: #4267b2;
    color: #fff;
}

.ladiui.auth .social-login > a.login-google:last-child {
    background: #d93025;
    color: #fff;
}

.ladiui.auth .forgot-password {
    float: right;
    text-align: right;
}

.ladiui.auth .forgot-password a {
    color: #6c7293;
    font-size: 14px;
}

.ladiui.auth .password-box {
    margin-bottom: 20px;
    overflow: hidden;
}

.ladiui.auth span.login-with {
    display: block;
    width: 100%;
    position: relative;
    border-top: 1px solid #ecedee;
    margin-top: 25px;
}

.ladiui.auth span.login-with span {
    margin: 0px auto;
    top: -12px;
    background: #fff;
    padding: 0px 5px;
    position: relative;
}

.ladiui.auth .question-form {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.ladiui.auth .question-form a {
    color: inherit;
    font-weight: 600;
}

.ladiui.auth .ladipage-terms {
    text-align: center;
}

.ladiui.auth .ladipage-terms ul {
    margin: 0px;
    padding: 0px;
}

.ladiui.auth .ladipage-terms ul li {
    display: inline-block;
}

.ladiui.auth .ladipage-terms ul li a {
    color: inherit;
    margin: 0px 20px;
    font-size: 14px;
}

.ladiui.auth .ladipage-terms ul li a:hover {
    text-decoration: underline;
}

.ladiui.table .ladiui-navbar.-horizontal li > ul {
    right: 0px;
}

.ladiui.table thead tr th {
    position: relative;
}

.ladiui.table thead tr th.sorting {
    cursor: pointer;
}

.ladiui.table thead tr th.sorting.up::after {
    content: '\ea47';
    font-family: 'LadiIcons';
    font-size: 10px;
    /* top: 20px; */
    /* top: 0px; */
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui.table thead tr th.sorting::after,
.ladiui.table thead tr th.sorting.down::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 8px;
    /* top: 21px; */
    top: unset;
    position: absolute;
    z-index: 0;
    color: #8697a8;
    padding-left: 6px;
}

.ladiui-topbar.fixed {
    position: fixed;
    width: 100%;
    left: 0px;
    z-index: 10;
}

.ladiui.fixed-sidebar .ladiui-aside {
    z-index: 11;
}

.ladiui-topbar .topbar-left {
    position: relative;
    left: 0px;
}

.ladiui-content {
    padding-top: 48px;
    display: grid;
    position: relative;
}

.ladiui-order-right .order-customer {
    position: relative;
}

.order-head-right {
    position: absolute;
    top: 70px;
    right: 33px;
}

.order-head-right a {
    color: inherit;
}

.order-customer-edit {
    display: block;
    /* position: absolute;
  top: 10px;
  right: 15px; */
    color: var(--main-primary);
}

.row-actions > li > a {
    line-height: inherit;
}

.pagination-block .per-page ul {
    bottom: 50px;
    top: inherit;
}

.ladiui-search-custom::after {
    content: '\ea41';
    font-family: 'LadiIcons';
    font-size: 18px;
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.ladipage-account {
    font-size: 14px;
}

.ladipage-account .ladipage-account-avatar {
    margin-top: 20px;
    width: 60px;
    float: left;
    margin-right: 35px;
}

.ladipage-account .ladipage-account-avatar img {
    width: 74px;
    height: 74px;
    border-radius: 50%;
}

.ladipage-account {
    padding: 0px 20px !important;
}

.ladipage-account-info > span {
    display: block;
    line-height: 24px;
}

.ladipage-account-name {
    font-weight: 500;
}

.ladipage-account-email {
    font-size: 12px;
}

.ladipage-account-info {
    padding: 20px 0px;
}

.ladipage-account-signout {
    text-align: right;
}

.icon-form-account {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.highcharts-point {
    rx: unset !important;
    ry: unset !important;
}

.text-tooltip {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.point-tolltip {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    /* background: #ff5849; */
}

.highcharts-credits {
    display: none !important;
}

.product-note {
    white-space: pre-line;
}

.ladiui.modal .search-field {
    height: 32px;
}

.ladiui.modal .search-group i {
    top: 3px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.btn-primary {
    margin: 0px 0px 0px 0px;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 64px;
    border-left: 1px solid var(--main-line);
    width: calc(100% - 236px);
    min-width: 1012px;
    height: 91.5vh;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    padding: 64px 0px 5px 0px;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}

.ladiui.btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 0px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
}

.ladiui.table {
    margin-top: 12px;
    /* min-width: 1012px; */
    width: 100%;
}

/* .ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
} */

.ladiui.content-main-tool {
    margin-top: 28px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}
.ladiui.custom-btn-list-landingpage button {
    width: 100%;
}

.ladiui-btn-tag.landingpage-list {
    margin: 4px 4px 4px 0px;
}

.ladiui-btn-tag.landingpage-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.landingpage-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}

/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 64px !important;
}

.ladiui.dropdown-text.custom-pre-page {
    margin: 0;
    padding: 0px 16px 0px 0px;
}

/* .ladiui.dropdown-toggle.custom-pre-page:after {
  margin: 12px 12px 12px 0px;
} */

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 64px;
    bottom: 0;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 18px;
}

.ladiui.setting-item {
    margin: 32px 0;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 0;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: 65px;
}

.ladiui.shared.setting-content-right {
    width: 636px;
    padding-bottom: 18px;
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}

/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}

.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 8.73px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start !important;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 65px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

.ladiui.intro-body-content span {
    margin-bottom: 8px;
}

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}

.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}

/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 12px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.modal-footer.custom-ldp {
    padding: 48px 0px 0px 0px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;
}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 300px !important;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    left: 24px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

/* .ladiui.modal .btn-group {
  padding: 0;
  width: 552px;
} */

.ladiui.modal .btn-group .dropdown-toggle {
    min-width: 160px;
}

/* .ladiui.modal .btn-group .dropdown-menu {
  width: 552px;
} */

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}

.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.modal-footer.custom-ldp1 {
    padding: 24px 0px 0px 0px;
    justify-content: left;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;
}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 80px;
    width: 80px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 136px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu {
    min-width: 65px;
    width: 65px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary);
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 200px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 320px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 310px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute);
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;
}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

/* .ladiui.linking-form-group::-webkit-scrollbar {
  width: 3px;
} */

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.content-main-tab {
    padding-bottom: 16px;
    margin-top: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.content-main-tab-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.content-main-tab-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.content-main-item-hover {
    display: none;
    position: absolute;
    top: 20px;
    left: -72px;
    background-color: var(--main-white);
    z-index: 999;
}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-main-item-hover {
    display: block;
}

.ladiui.content-main-tab-item.tow:hover .ladiui.content-main-item-hover.tow {
    display: block;
}

.ladiui.content-main-tab-item.three:hover .ladiui.content-main-item-hover.three {
    display: block;
}

.ladiui.content-main-tab-item.for:hover .ladiui.content-main-item-hover.for {
    display: block;
}

.ladiui.content-main-item-hover.tow {
    width: 265px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-tab-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    margin-left: 32px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.content-tab-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.ladiui.content-main-tab-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.content-main-tab-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    min-width: calc(100% / 3 - 20px);
    min-height: 290px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }
}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 107px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;
}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

.col-50 {
    width: 48%;
    float: left;
    margin: 0px 15px;
}

.ladiui-row > .col-50:first-child {
    margin-left: 0px;
}

.ladiui-row > .col-50:last-child {
    margin-right: 0px;
    float: right;
}

.ladiui-search-dropdown {
    border-radius: 4px;
    position: relative;
    min-width: 200px;
    height: 40px;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ladiui-search-dropdown > input.ladiui-search-input {
    padding: 0px 15px;
    border: 1px solid #ebedf2;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    background: #fff;
    cursor: pointer;
    color: #313942;
    top: 0;
    left: 0px;
    width: 100%;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ladiui-search-dropdown > .text {
    display: inline-block;
    -webkit-transition: none;
    transition: none;
    cursor: text;
    position: relative;
    left: 3px;
    z-index: 3;
    line-height: 40px;
    pointer-events: none;
    font-weight: 400;
    color: #313942;
    width: 100%;
    padding: 0px 15px;
}

.ladiui-search-dropdown > .text.open {
    color: #b3b3b3 !important;
    font-weight: 300;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu {
    border-color: #96c8da;
    -webkit-box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    /* box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15); */
    box-shadow: 0 -1px 38px rgb(0 0 0 / 5%), 0 4px 7px rgb(0 0 0 / 12%);
    position: absolute;
    top: 42px;
    left: 0px;
    border-radius: 4px;
    display: none;
    background: #fff;
    height: max-content;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
    z-index: 10;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu > .item {
    position: relative;
    cursor: pointer;
    white-space: normal;
    word-wrap: normal;
    display: block;
    height: auto;
    padding: 10px 15px;
    text-align: left;
    border-top: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    box-shadow: none;
    background: #fff;
    line-height: 24px;
    border-bottom: 1px solid #f8fafb;
}

.ladiui-search-dropdown .ladiui-search-dropdown-menu > .item:hover {
    background: #f8fafb;
}

.ladiui-search-dropdown > span {
    display: block;
    z-index: 9999;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: -3px;
    cursor: pointer;
}

.ladiui-search-dropdown > span {
    position: relative;
}

.ladiui-search-dropdown > span::after {
    display: block;
    z-index: 2;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 4px;
    cursor: pointer;
}

.ladiui-search-dropdown::after {
    content: '\ea4a';
    font-family: 'LadiIcons';
    font-size: 12px;
    display: block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
    line-height: 40px;
    top: 0px;
    cursor: pointer;
    z-index: 2;
}

.hide-mt .ladiui.dropdown-toggle:after {
    /* display: none !important; */
    /* background: #ffffff !important; */
    background: unset !important;
}

.ba-c .ladiui.dropdown-toggle {
    min-width: unset !important;
    border: none !important;
}

.ladiui.icon-ldp-fb-group {
    width: 14px !important;
    height: 14px !important;
}

.page-checkout-type::first-letter {
    text-transform: uppercase;
}

.ladiui.caption {
    white-space: nowrap;
}
.tox-notifications-container {
    height: 0px !important;
    overflow: hidden !important;
}

input.ladiui.readonly {
    background: #eeeeee;
    color: black !important;
}