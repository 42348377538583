.btn-order-table {
    padding: 0px 10px;
    font-size: 14px;
    line-height: 32px;
}

.btn-add-product {
    color: var(--main-primary);
    font-size: 14px;
    line-height: 20px;
}

.center-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
}

.footer-order {
    padding-top: 24px;
    border-top: 1px solid #F1F3F4;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.footer-order .item-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}
/* .footer-order #dropdown-customer-field {
    width: 455px !important;
} */

.w-49-p {
    width: 49% !important;
}

.title-lable {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.footer-lable {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
}

.item-product-add-new {
    padding-top: 20px;
    cursor: pointer;
}

.item-product-add-new:first-child {
    padding-top: 0px;
}

.item-product-add-new .product-name:hover {
    color: var(--main-primary);
}

.list-product {
    overflow: auto;
    max-height: 450px;
    padding: 5px;
}

.w-70 {
    width: 70px;
}

.w-310 {
    width: 310px;
}

.w-120 {
    width: 120px;
}

.item-variant {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-top: solid 1px #F1F3F4;
    padding: 24px 0px;
}

.item-variant:first-child {
    padding-top: 0px !important;
}

.item-variant-name-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 82px;
}

.ladiui-order-left .item-variant-name-info .item-title {
    margin-bottom: 14px;
}

.item-variant-price {
    display: flex;
    border: solid 1px #f1f3f5;
    margin-top: -10px;
}

.item-variant-price-input {
    text-align: center;
    border: none !important;
    width: 53px !important;
}

.item-variant-list-option {
    display: flex;
    flex-direction: column;
}

.info-customer {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    /* padding: 20px !important; */
}

.order-status {
    background: #41B983;
    border-radius: 2px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    font-size: 12px;
    padding: 2px 8px;
    color: #FFFFFF;
}

.btn-action-bc {
    border: 1px solid var(--main-primary);
    width: 32px;
    height: 32px;
    text-align: center;
}

.title-16 {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(--main-default) !important;
}

.order-status.Canceled {
    background: #dfe4e8 !important;
    color: #000000;
}

.order-status.refund-all {
    background: #dfe4e8 !important;
    color: #000000;
}

.ladiui-order-right {
    width: 330px;
    position: absolute;
    /* top: 199px; */
    right: 24px;
}

.ladiui-order-right .more-info {
    display: grid;
}

.w-name-product {
    width: calc(100% - 610px) !important;
}

.email-check-send {
    position: absolute !important;
    left: 30px !important;
}

.list-product-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-product-type .item-product {
    width: 49%;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 16px;
    margin-bottom: 16px;
}

.list-product-type .item-product .des-product {
    font-size: 13px;
    line-height: 19px;
    color: #6D6D6D;
}

.input-price::after {
    background-size: cover;
    content: "đ";
    height: 8px;
    margin: 14px 14px 14px 16px;
    position: absolute;
    right: 0;
    width: 8px;
}

.product-create {
    width: 620px;
    margin: auto;
    margin-top: 80px;
}

.sub-icon-group {
    position: absolute;
    right: 7px;
    top: 7px;
    color: #727272;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.ladiui.tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 4px 8px;
    /* padding: 0px 8px; */
}

.tags .tag {
    width: unset;
    margin-right: 10px;
}

.btn-coppy {
    margin-left: 10px;
}