.valid-input-error {
    border: 1px solid red !important;
}

.text-default {
    color: #6d6d6d;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.mini-dropdown {
    width: 100% !important;
    height: 22px !important;
    min-width: unset !important;
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.mini-dropdown.fix {
    width: 100% !important;
}

.mini-dropdown-pd {
    padding: 2px 5px !important;
}

.head-accordion {
    background: #f1f3f4 !important;
    border-radius: 2px !important;
}

.min-max-buy {
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
}

.variant-option-item .variant-option-value {
    width: calc(100% - 380px);
    margin-left: 20px;
}

.btn-add-option {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding-left: 0px !important;
    cursor: pointer;
    width: fit-content;
}

.un-hover:hover,
.un-hover:focus {
    border: unset !important;
}

/* .list-image-product .list-image .item-image {
    margin-right: 23px;
} */

.list-image-product .list-image.grid-box {
    /* display: flex;
    align-items: center;
    width: 400px;
    overflow: auto; */
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 24px;
}

.list-image-product .list-image.flex-box {
    display: flex;
}

.list-image-product .list-image.flex-box .item-image {
    margin-right: 24px !important;
}

.list-image-product .list-image .btn-add-image {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #afafaf;
    padding-top: 20px;
}

.list-image-product .image-none .ladiui-button-image {
    position: relative;
    width: 400px;
    height: 400px;
    background: #f1f3f4;
    border-radius: 2px;
}

.list-image-product .image-none .btn-add-image {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #afafaf;
    padding: 150px 80px;
}

.list-image-product .image-none .ladiui-button-image input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.item-variant-list-option .option-name {
    color: #6d6d6d;
}

.item-variant-list-option .option-value {
    color: var(--main-default);
}

.block-custom-interface {
    margin-bottom: 30px;
}

.ladiui.iframe-checkout {
    text-align: center;
    background: #d9d9d9 !important;
    padding: 20px 0px;
}

#iframe-checkout-page {
    height: calc(100vh - 340px);
}

#iframe-store-info {
    height: calc(100vh - 200px);
}

#iframe-interface {
    height: calc(100vh - 200px);
}

#iframe-general-store {
    height: calc(100vh - 200px);
}

.tox-editor-container .tox-editor-header .tox-menubar {
    display: none;
}

.publish-redirect-after .ladiui.dropdown-text {
    width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0px;
}