#home-page {
    padding: 24px;
    background-color: #fff;
}
#home-page-info .content {
    width: 100%;
    display: flex;
}
#home-page-info .content .right-content {
    width: 100%;
    background-color: #fff;
    padding-left: 24px;
    padding-right: 24px;
    border-left: 1px solid #f1f3f4;
}

#home-page-info .content .ladiui.setting-item.active .title {
    color: var(--main-primary);
}
#home-page-info .content .right-content .panel-store-info .block-title-action {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    align-items: center;
    margin-bottom: 24px;
}
#home-page-info .content .right-content .panel-store-info .block-title-action .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--main-default);
}
.panel-store-info .main-content {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}
.panel-store-info .main-content .left-content {
    flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 60%;
}
#store-banner {
    position: relative;
}

.panel-store-info .main-content .left-content .preview-store-banner #store-banner .store-banner {
    width: 160px;
    height: 90px;
    border-radius: 2px;
    object-fit: cover;
}

.panel-store-info .main-content .left-content .preview-store-banner #store-banner:hover #store-logo-overlay {
    width: 160px;
    height: 90px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#home-page-info .content .right-content .panel-store-info .right-content {
    border: none;
    padding: 16px 32px;
    background-color: #D9D9D9;
    margin-left: 24px;
}
#dropdown-active-chanel-store {
    max-width: 400px
}

.block-setting-general-store .block-domain {
    display: block;
    margin-top: 24px;
    width: 100%;
}
.block-setting-general-store .block-domain .title {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #4D4D4D;
}
.block-homepage-interface .main-content .block-interfaces {
    position: relative;
}
.block-homepage-interface .main-content .block-interfaces .choose-content {
    display: block;
    position: absolute;
    z-index: 9999;
    left: 0;
    background-color: #fff;
    box-shadow: 0 -1px 38px rgba(0,0,0,.05), 0 4px 7px rgba(0,0,0,.12);
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li {
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 0;
    cursor: pointer;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li:hover {
    background-color: #f1f3f4;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li .image {
    padding: 0px 18px;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li .image img {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li .content h3 {
    font-weight: 500;
}
.block-homepage-interface .main-content .block-interfaces .choose-content ul>li .content {
    display: block !important;
}

.block-homepage-interface .block-list-locations .location-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.block-homepage-interface .block-list-locations .location-item:last-child {
    margin-bottom: 0;
}
.block-homepage-interface .block-list-locations .location-item .content {
    padding: 11px 12px;
    background-color: #f1f3f4;
    border-radius: 2px;
}
.block-homepage-interface .block-list-locations .location-item .content .action-item .btn-edit {
    margin-right: 14px;
}
.block-homepage-interface .block-list-locations .location-item .content .item-text span {
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 20px;
    max-width: 350px;
}

#homepage-add-banner .block-content-image {
    background-color: #e5e5e5;
    height: 230px;
    text-align: center;
}
#homepage-add-banner .block-content-image .block-upload {
    color: #6d6d6d;
    cursor: pointer;
    padding-top: 65px;
    width: max-content;
    margin: 0px auto;
}
#homepage-add-banner .block-show-image {
    width: 100%;
    position: relative;
}
#homepage-add-banner .block-show-image .image img {
    width: 100%;
    max-height: 230px;
    object-fit: cover;
}
#homepage-add-banner .block-show-image .block-action-item {
    align-items: center;
    position: absolute;
    top: 4px;
    right: 4px;
    display: none;
}
#homepage-add-banner .block-show-image .block-input-add-link {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
}
#homepage-add-banner .block-show-image .block-input-add-link input {
    height: 24px!important;
    width: 100%!important;
    margin: 0!important;
    padding-right: 30px;
    font-size: 12px;
}
#homepage-add-banner .block-show-image .block-input-add-link .ldicon-save {
    position: absolute;
    right: 8px;
    cursor: pointer;
    bottom: 6px;
}
#homepage-add-banner .block-show-image:hover .block-action-item {
    display: flex;
}
#homepage-add-banner .block-show-image:hover .block-action-item .item {
    background-color: rgba(32,32,32,.7);
    padding: 3px 5px;
    border-radius: 2px;
    cursor: pointer;
}
#homepage-add-banner .block-show-image:hover .ldicon-link, #homepage-add-banner .block-show-image:hover .ldicon-delete {
    background-color: #fff;
}
.table-store-add-discount tr th {
    padding-right: 0px !important;
}
.table-store-add-discount tr td {
    padding-right: 0px !important;
}

#homepage-add-product-modal .block-display-type {
    display: block;
    margin-top: 24px;
}
#homepage-add-product-modal .block-display-type .block-content {
    display: flex;
    gap: 12px;
    flex-wrap: nowrap;
}
#homepage-add-product-modal .block-display-type .block-content .block-item {
    width: calc(50% - 6px);
    border-radius: 2px;
    border: 1px solid #d1d1d1;
    cursor: pointer;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.left {
    padding: 48px 0;
}
#homepage-add-product-modal .block-display-type .block-content .block-item .list-pr-exam {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .top {
    width: 60px;
    height: 60px;
    background-color: #e5e5e5;
    border-radius: 2px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.left .list-pr-exam .pr-item .bottom {
    width: 60px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 2px;
    margin-top: 4px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.right .list-pr-exam .pr-item .top {
    width: 50px;
    height: 50px;
    background-color: #e5e5e5;
    border-radius: 2px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.right .list-pr-exam .pr-item .bottom {
    width: 50px;
    height: 8px;
    background-color: #e5e5e5;
    border-radius: 2px;
    margin-top: 4px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.right .list-pr-exam {
    flex-wrap: wrap;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.right {
    padding: 20px 50px;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.active {
    border: 1px solid #1c00c2;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.active .list-pr-exam .pr-item .top {
    background-color: #D3C9FB;
}
#homepage-add-product-modal .block-display-type .block-content .block-item.active .list-pr-exam .pr-item .bottom {
    background-color: #D3C9FB;
}
#homepage-add-product-modal .ladiui.modal-content {
    animation: none!important;
}
#homepage-discount-modal .ladiui.modal-content {
    animation: none!important;
}