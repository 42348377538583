/** @format */

.ldicon-note {
    background-color: var(--main-secondary);
    display: inline-block;
    box-sizing: unset;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    mask-image: url(https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg);
    width: 16px;
    height: 16px;
}

.new-ldicon-close-fill {
    width: 16px;
    height: 16px;
    box-sizing: unset;
    display: inline-block;
    mask-size: cover;
    mask-image: url(https://w.ladicdn.com/ladiui/icons/new-ldicon-close-fill-2.svg);
}
